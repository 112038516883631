export default defineNuxtRouteMiddleware(() => {
  const { $pinia } = useNuxtApp();
  const { tokenUser } = storeToRefs(useTokenUserStore($pinia));

  if (!tokenUser.value) {
    return;
  }

  return navigateTo("/account/login");
});
